import React, { useEffect, useState } from 'react'
import Header from '../Shared Components/Header'
import HOME from '../Assets/home_img.webp'
import NoDataScreen from '../Shared Components/NoDataScreen';
import { Helmet } from 'react-helmet-async'
import { Document, Page } from 'react-pdf';
import HISTORYPDF from '../Assets/Palli_history.pdf'
import { pdfjs } from 'react-pdf';

function About() {
  const VIDEO = ''

  const scheduleData = [
    {
      day: 'Sunday',
      services: [
        { time: '7.00 am', service: 'Morning Prayer' },
        { time: '8.00 am', service: 'Holy Qurbana' }
      ]
    },
    {
      day: 'Saturday & Other Days',
      services: [
        { time: '6.30 am', service: 'Morning Prayer' },
        { time: '7.00 am', service: 'Holy Qurbana' }
      ]
    },
    {
      day: 'Wednesday',
      services: [
        { time: '5.30 pm', service: 'Evening Prayer' }
      ]
    }
  ];
  
  const [numPages, setNumPages] = useState();

  function onDocumentLoadSuccess({numPages}) {
    setNumPages(numPages);
  }

  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/legacy/build/pdf.worker.min.mjs',
       import.meta.url,
     ).toString();

  const [showPdf, setShowPdf] = useState(window.innerWidth > 770);
  const handleResize = () => {
    const isSmallScreen = window.innerWidth > 770;
    setShowPdf(isSmallScreen);
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <>
      <Helmet>
        <meta name="description" content="Discover the rich history, dedicated parish administration, regular services, and deep spiritual tradition of St. Mary's Orthodox Church, Haripad."/>
        <link rel='canonical' href='/about'/>
      </Helmet>
      <Header VIDEO={VIDEO} HOME={HOME} main_head={"About"} sub_head={"Church"} showBtn={false}/>
      <div className="container">
        <h6 className='mt-5'>History</h6> 
        <hr />
        <div className='pdf-view'>
          <Document file={HISTORYPDF} onLoadSuccess={onDocumentLoadSuccess} className={`d-flex ${!showPdf ? 'flex-column' : 'flex-row'} justify-content-start align-items-center overflow-hidden`} style={{width : '300px'}}>
            {
              Array.apply(null, Array(numPages))
              .map((x,i)=> i+1)
              .map((page)=>{
                return (
                  <Page pageNumber={page} renderTextLayer={false} renderAnnotationLayer={false} key={page} />
                )
              })
            }
          </Document>
        </div>
        <h6 className='mt-5'>Service Timing</h6> 
        <hr />
        <table className="table table-bordered mb-5">
          <thead>
            <tr>
              <th>Day</th>
              <th>Time</th>
              <th>Service</th>
            </tr>
          </thead>
          <tbody>
            {scheduleData.map((item, index) => (
              <React.Fragment key={index}>
                {item.services.map((service, serviceIndex) => (
                  <tr key={serviceIndex}>
                    {serviceIndex === 0 && (
                      <td rowSpan={item.services.length}>{item.day}</td>
                    )}
                    <td>{service.time}</td>
                    <td>{service.service}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
        <h6 className='mt-5'>Parish Administration</h6> 
        <hr />
        <NoDataScreen />
      </div>
    </>
  )
}

export default About