import React, { useEffect, useRef } from 'react';

function Preview({ toggle, onClose, header, imgSrc }) {
  const modalRef = useRef(null);

  useEffect(() => {
    if (toggle) {
      // Show the modal and add the overlay
      modalRef.current.classList.add('show');
      modalRef.current.style.display = 'block';
      document.body.classList.add('modal-open');

      // Create the backdrop element
      const backdrop = document.createElement('div');
      backdrop.className = 'modal-backdrop fade show';
      document.body.appendChild(backdrop);
    } else {
      // Hide the modal and remove the overlay
      modalRef.current.classList.remove('show');
      modalRef.current.style.display = 'none';
      document.body.classList.remove('modal-open');

      // Remove the backdrop element
      const backdrop = document.querySelector('.modal-backdrop');
      if (backdrop) {
        backdrop.remove();
      }
    }
  }, [toggle]);

  return (
    <div
      className="modal fade"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden={!toggle}
      ref={modalRef}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title">{header}</h6>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            ></button>
          </div>
          <div className="modal-body">
            <img
              src={imgSrc}
              alt={header}
              style={{ padding: '1.3125rem', width: '100%', minHeight: '50vh' }}
              className="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Preview;
