import React from 'react'
import HOME from '../Assets/home_img.webp'
import Header from '../Shared Components/Header'
import { Helmet } from 'react-helmet-async'

function News() {
    const VIDEO = ''
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex"/>
      </Helmet>
      <Header VIDEO={VIDEO} HOME={HOME} main_head={"News & Events"} sub_head={""} showBtn={false}/>
      <div className="container mb-5">
        <h6 className='mt-5'>Perunnal Related</h6> 
        <hr />
        <p><i className="fa-solid fa-location-pin"></i> 25/08/2024, കൊടിമരഘോഷയാത്ര</p>
        <p><i className="fa-solid fa-location-pin"></i> 31/08/2024, പെരുന്നാൾ കൊടിയേറ്റ്</p>
        <p><i className="fa-solid fa-location-pin"></i> 01/09/2024, വിശുദ്ധ എട്ടുനോമ്പ് പെരുന്നാളിന്റെ ഒന്നാം ദിവസം</p>
        <p><i className="fa-solid fa-location-pin"></i> 02/09/2024, വിശുദ്ധ എട്ടുനോമ്പ് പെരുന്നാളിന്റെ രണ്ടാം ദിവസം</p>
        <p><i className="fa-solid fa-location-pin"></i> 03/09/2024, വിശുദ്ധ എട്ടുനോമ്പ് പെരുന്നാളിന്റെ മൂന്നാം ദിവസം</p>
        <p><i className="fa-solid fa-location-pin"></i> 04/09/2024, വിശുദ്ധ എട്ടുനോമ്പ് പെരുന്നാളിന്റെ നാലാം ദിവസം</p>
        <p><i className="fa-solid fa-location-pin"></i> 05/09/2024, വിശുദ്ധ എട്ടുനോമ്പ് പെരുന്നാളിന്റെ അഞ്ചാം ദിവസം</p>
        <p><i className="fa-solid fa-location-pin"></i> 06/09/2024, വിശുദ്ധ എട്ടുനോമ്പ് പെരുന്നാളിന്റെ ആറാം ദിവസം</p>
        <p><i className="fa-solid fa-location-pin"></i> 07/09/2024, വിശുദ്ധ എട്ടുനോമ്പ് പെരുന്നാളിന്റെ ഏഴാം ദിവസം</p>
        <p><i className="fa-solid fa-location-pin"></i> 08/09/2024, വിശുദ്ധ എട്ടുനോമ്പ് പെരുന്നാളിന്റെ എട്ടാം ദിവസം</p>
      </div>
    </>
  )
}

export default News