import React, { useState } from 'react'
import HOME from '../Assets/home_img.webp'
import Header from '../Shared Components/Header'
import { Helmet } from 'react-helmet-async'
import imgSrc from '../Assets/Day1.webp'
import Preview from '../Shared Components/Preview'

function Live() {
  const [toggle, setToggle] = useState(false)    
    const VIDEO = ''
  return (
    <>
      <Helmet>
        <meta name="description" content="Tune in to the live streams of St. Mary's Orthodox Church, Haripad, and experience our services, spiritual traditions, and community events from anywhere."/>
        <link rel='canonical' href='/live-streams'/>
      </Helmet>
      <Header VIDEO={VIDEO} HOME={HOME} main_head={"Live Streams"} sub_head={""} showBtn={false}/>
      <div className="container mt-3">
        <h6>08/09/2024 - വിശുദ്ധ എട്ടുനോമ്പ് പെരുന്നാളിന്റെ എട്ടാം ദിവസം..</h6>
        <hr />
        <Preview toggle={toggle} onClose={() => setToggle(false)} header={'Day 1 Poster'} imgSrc={imgSrc}/>
      </div>
      <div className="container d-flex align-items-center justify-content-center my-5">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/5i2AxSePpD4?si=5wANkadveGHv7dXN" title="Arazhipally" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
      </div>
    </>
  )
}

export default Live