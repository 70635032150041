import React from 'react'
import Header from '../Shared Components/Header'
import HOME from '../Assets/home_img.webp'
import { Helmet } from 'react-helmet-async';

function Contact() {
  const VIDEO = ''
  const date = new Date();
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex"/>
      </Helmet>
      <Header VIDEO={VIDEO} HOME={HOME} main_head={"Contact Us"} sub_head={""} showBtn={false}/>
      <div className='container' style={{ width: '100%' }}>
        <h6 className='mt-5'>Contact Address</h6> 
        <hr />
        <div className="content">
          <p>St.Mary's Orthodox Church</p>
          <p>Haripad, Alappuzha, Kerala - 690514</p>
          <p>Email: vicar@stmarysharipad.com</p>
          <p>Phone: 0479-2402445</p>
        </div>
        <h6 className='mt-5'>Social Media</h6> 
        <hr />
        <div className='social_media d-flex align-items-center justify-content-around flex-wrap gap-2'>
          <div className="fb">
            <i class="fa-brands fa-facebook"></i>
            <a href="https://www.facebook.com/arazhipallyharipad" target='_blank' rel="noopener noreferrer"> ഹരിപ്പാട് ആരാഴിപ്പളളി <i class="fa-solid fa-arrow-up-right-from-square"></i></a>  
          </div>
          <div className="instagram">
            <i class="fa-brands fa-instagram"></i>
            <a href="https://www.instagram.com/ocym_arazhi_pally/" target='_blank' rel="noopener noreferrer"> ഹരിപ്പാട് ആരാഴിപ്പളളി <i class="fa-solid fa-arrow-up-right-from-square"></i></a>  
          </div>
          <div className="email">
            <i class="fa-regular fa-envelope"></i>
            <a href="mailto:arazhipalliharipad@gmail.com"> ഹരിപ്പാട് ആരാഴിപ്പളളി <i class="fa-solid fa-arrow-up-right-from-square"></i></a>  
          </div>
          <div className="whatsapp">
            <i class="fa-brands fa-whatsapp"></i>
            <a href="https://wa.me/9188840555" target='_blank' rel="noopener noreferrer"> ഹരിപ്പാട് ആരാഴിപ്പളളി <i class="fa-solid fa-arrow-up-right-from-square"></i></a>  
          </div>
        </div>
        <h6 className='mt-5'>Location Map</h6> 
        <hr />
        <div style={{ width: '100%', maxWidth: '900px', margin: '50px 0' }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3937.576612585285!2d76.46479207456207!3d9.282068590789741!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b061ff9d66a66b1%3A0xd51c959f2e29c6ae!2sArazhy%20St%20.Mary&#39;s%20O.Church!5e0!3m2!1sen!2sin!4v1724519680436!5m2!1sen!2sin"
            style={{
              border: '0',
              width: '100%',
              height: 'calc(100% * 9 / 16)',
            }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title='Arazhy Haripad'
          ></iframe>
        </div>
        <hr />
        <div className="footer text-center">
          <p style={{fontSize : '10px', marginBottom: '5px'}}>Copyright © {date.getFullYear()} St.Mary's Orthodox Church, Haripad, All Rights Reserved.</p>
        </div>
      </div>
    </>
  )
}

export default Contact