import React, { useState } from 'react'
import LOGO from '../Assets/Logo.webp'
import Button from '../Shared Components/Button'
import './Styles/Header.scss'
import { Link, useNavigate } from 'react-router-dom'
import MainSection from './MainSection'
import { menuItems } from '../Router/RouterData'

function Header({VIDEO, HOME, main_head, sub_head, showBtn}) {
  const [isOpen, setIsOpen] = useState(false)
  const history = useNavigate()

  const updateParentState = (newState) => {
    setIsOpen(newState);
  };

  function handleDataFromChild(data) {
    history('/donate')
  }

  return (
    <>
      <div className="nav-section d-flex align-items-center justify-content-between flex-column"> 
        <div className='container d-flex align-items-center justify-content-between pt-2'>
          <div className="left d-flex align-items-center">
            <a href="https://www.stmarysharipad.com/">
              <img src={LOGO} alt="https://www.stmarysharipad.com/" width={'70px'} />
            </a>
            <div className='site_name'>
              <p style={{'fontWeight' : '700'}}>St. Mary's Orthodox Church</p>
              <p className='sub_head'>Haripad, Alappey</p>
            </div>
          </div>
          <Button name="Donations" color="notNeeded" hide="true" eventToParent={handleDataFromChild}/>
          <i className="fa-solid fa-bars fs-4 d-block d-sm-none" onClick={ () => setIsOpen(!isOpen) }></i>
        </div>
        <nav className='d-none d-sm-block'>
          <div className="container">
            <ul className='navbar-nav mr-auto flex-row justify-content-between'>
              {
                menuItems.map((menu,index)=>{
                  return <li className={`nav-item ${menu?.subMenu?.length === 0 && 'dropdown'}`} key={index}>
                    {
                    menu?.subMenu?.length === undefined ? (
                      <Link className='nav-link' to={menu?.url}>{menu?.title}</Link>
                    ) : (
                      <>
                          <Link 
                              className="nav-link dropdown-toggle" 
                              href="#"
                              id="navbarDropdown"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"   
                          >
                            {menu?.title}
                          </Link>
                          <ul className="dropdown-menu subChild" aria-labelledby="navbarDropdown"> 
                              {
                                menu?.subMenu.map((sub,ind)=>{
                                  return <li className='nav-item p-1' key={ind}><Link className="nav-link" to={sub?.url}>{sub?.title}</Link></li>
                                })
                              }
                          </ul>
                      </>
                  )
                  
                    }
                    </li>
                })
              }
            </ul>
          </div>
        </nav>
      </div>
      <MainSection videoSrc={VIDEO} homeSrc={HOME} main_head={main_head} sub_head={sub_head} isOpen={isOpen} setOpen={updateParentState} menuItems={menuItems} showBtn={showBtn}/>
    </>
  )
}

export default Header   