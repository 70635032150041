import React from 'react'
import Header from '../Shared Components/Header'
import HOME from '../Assets/home_img.webp'
import { Helmet } from 'react-helmet-async'

function Charity() {
    const VIDEO = ''
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex"/>
      </Helmet>
     <Header VIDEO={VIDEO} HOME={HOME} main_head={"Donations"} sub_head={""} showBtn={false}/>
     <div className="container mt-5">
      <table className="table table-bordered mb-5">
            <thead>
              <tr>
                <th>A/c no.</th>
                <th>IFSC</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>57028404772</td>
                <td>SBIN0070086</td>
              </tr>
            </tbody>
        </table>
     </div>
    </>
  )
}

export default Charity