import React from 'react'
import maryImg from '../Assets/mary.png'
import './Styles/Loader.scss'

function Loader() {
  return (
    <div className='splash-container'>
        <img src={ maryImg } alt="St Mary" width={'110px'} />
    </div>
  )
}

export default Loader