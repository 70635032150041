import React from 'react'

function NoDataScreen() {
  return (
    <div className='container my-5'>
        <div className="content d-flex align-items-center justify-content-center flex-column" style={{width:'100%'}}>
            <i className="fa-solid fa-gears"></i>
            <p>Sorry!... No data found</p>
        </div>
    </div>
  )
}

export default NoDataScreen