import React from 'react'

function Button({ name, color, bgColor, hide, eventToParent, newCls }) {
  const handleClick = () => {
    eventToParent(true);
  }
  return (
    <input onClick={handleClick} type="button" value={name} className={`buttonStyle ${hide === 'true' ? `d-none d-sm-block` : ''} ${newCls}`} style={{backgroundColor : bgColor,color: color}} />
  )
}

export default Button