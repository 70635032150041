import { useEffect, useState } from 'react';
import '../src/styles/App.scss';
import Routers from "./Router/Routers";
import Loader from './Shared Components/Loader'
import { useLocation } from 'react-router-dom';

function App() {
  const [loader, setLoader] = useState(true)
  const location = useLocation();

  useEffect(() => {
    setLoader(true);
    const loaderTimeout = setTimeout(() => {
      setLoader(false);
    }, 2000);
    return () => {
      clearTimeout(loaderTimeout);
    };
  }, [location]);

  return (
    <>
    { loader ? <Loader /> :  <Routers /> }
    </>
  );
}

export default App;
