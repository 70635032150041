import About from "../Pages/About";
import Home from "../Pages/Home";
import SundaySchool from "../Pages/SundaySchool";
import Youth from "../Pages/Youth";
import Samajam from "../Pages/Samajam";
import Contact from "../Pages/Contact";
import News from "../Pages/News";
import Live from "../Pages/Live";
import EttuNomb from "../Pages/EttuNomb";
import Choir from "../Pages/Choir";

const date = new Date();

export const menuItems = [
    {
      title : 'Home',
      url : '/',
      element : <Home />,
    },
    {
      title : 'About Church',
      url : '/about',
      element : <About />
    },
    {
      title : 'Organizations',
      subMenu : [
        {
          title : 'Sunday School',
          url : '/sunday-school',
          element : <SundaySchool />
        },
        {
          title : 'Youth League',
          url : '/youth-league',
          element : <Youth />
        },
        {
          title : 'Marthamariyam Samacham',
          url : '/marthamariyam-samacham',
          element : <Samajam />
        },
        {
          title : 'Church Choir',
          url : '/church-choir',
          element : <Choir />
        }
      ]
    },
    {
      title : 'News & Events',
      url : '/news-events',
      element : <News />
    },
    {
      title : 'Live Streams',
      url : '/live-streams',
      element : <Live />
    },
    {
      title : `Ettu Nomb ${date.getFullYear()}`,
      url : `/ettu-nomb-${date.getFullYear()}`,
      element : <EttuNomb />
    },
    {
      title : 'Contact Us',
      url : '/contact-us',
      element : <Contact />
    },
  ]