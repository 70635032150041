import React from 'react'
import Header from '../Shared Components/Header'
import NoDataScreen from '../Shared Components/NoDataScreen'
import HOME from '../Assets/home_img.webp'
import { Helmet } from 'react-helmet-async'

function Choir() {
    const VIDEO = ''
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex"/>
      </Helmet>
      <Header VIDEO={VIDEO} HOME={HOME} main_head={"Organizations"} sub_head={"Church Choir"} showBtn={false}/>
      <NoDataScreen />
    </>
  )
}

export default Choir