import { Route, Routes } from "react-router-dom";
import { menuItems } from "./RouterData";
import NotFound from "../Pages/NotFound";
import React from 'react'
import Charity from "../Pages/Charity";

const Routers = () => {
  const generateRoutes = (items) => {
    return items.map(({ url, title, element, subMenu }) => {
      return (
        <React.Fragment key={title}>
          <Route path={url} element={element} />
          {subMenu && generateRoutes(subMenu)}
        </React.Fragment>
      );
    });
  };
  return (
    <Routes>
      {generateRoutes(menuItems)}
      {/* Wildcard Route */}
      <Route path="*" element={<NotFound />} />
      <Route path="/donate" element={<Charity />} />
    </Routes>
  );
};

export default Routers;
